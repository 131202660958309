import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmLivestreamPerm';
import UpdateBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/PrimaryBoxUpdates';
import InfoBox from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/PrimaryBoxInfo';
import YoutubeVideoCard from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/YoutubeVideoCard';
import AreasTable from 'components/Web_User_Interface/1440p_Series/Alarm/Areas/areasTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Areas",
  "path": "/Web_User_Interface/1440p_Series/Alarm/Areas/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The alarm areas detect motion by an image analysis that is sensitive to changing pixels inside the video frame instead of employing an infrared or microwave motion sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts it´s sensitivity to set a threshold for trigger events. Once the motion detection in an area is activated, its sensitivity can be adjusted from very insensitive (value=0) to very sensitive (value=100).",
  "image": "./WebUI_1440p_SearchThumb_Alarm_Alarm_Areas.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Areas.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Alarm Areas' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The alarm areas detect motion by an image analysis that is sensitive to changing pixels inside the video frame instead of employing an infrared or microwave motion sensor. You can setup up to 4 detection areas - just activate an area and draw its shape onto the image - and adjusts it´s sensitivity to set a threshold for trigger events. Once the motion detection in an area is activated, its sensitivity can be adjusted from very insensitive (value=0) to very sensitive (value=100).' image='/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Areas.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Alarm_Alarm_Areas.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Alarm/Bereiche/' locationFR='/fr/Web_User_Interface/1440p_Series/Alarm/Areas/' crumbLabel="Alarm Areas" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/05d874a5b48f090bc6bb7272b19de8d0/610c0/1440p_Settings_Alarm_Areas.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAD2klEQVQ4y3WTW2ibBRiGf7x04I0KgrAksyebts4T2q5rm8OfNElz+P/kT9L0kKZtunWSbMt6YOt569I0SZu0WdexKcJwDi2Iorauc8gQQRG9E7xQmFvnurXdbJHJvHmkcfRGvHh4+eDl4+N7eQXH6BWsQ4tI49dwnbqG8+TVPPaxZRpGr+SxPdaG0WXqB5cwDyzmtX5wEcvQEtbhJbyJ64hdSYRo7xCdkV4qTS4qzRLV9R6qLV5EZwsN7hB2TwcOpR27pw2b3ELwQISuaIyOt6KEI8fwtXUjN7bjaz2A0eJEKCkp4vWX9xLcV02uuYVZX4D55iCDip9G0UWzs5v+ni/oO7bI0chHnBpZZnx0kbHhz2gN9CI5rHjdEm63B51BRChXq/m2r5+1VJqt6Slup6ZZiPaRaW8hfbCLRP8Z5nL3mc1ukMs8IJ1cIxFfJZv5mzZ/BrvFiN/fiNLYit5kRXhy1zN8fCjCg1Sa9XSK28k0Z7sn6QsdZyDop6dzgMzU78ykb5GbvkU2vcLUxA1mpzc40nUO2WFFURQ83gB60YJgN1VyeSbOL2dzvN9+kM8Px/hmdJJoU4Q2X4hoOM7E+E3SyXukkptkMptkM+tksg/p7DiHw2HD7/ej+AIYjCJChepZVM+pMb6iJeyp46WyciwVe2mqexO7vZnOYBPDx2PEuob4JHueiyOXuDD2JfNTqwx05/C56lG8PhR/878XPvHULox9Zro+DHPmRpyTPwwTGnYTknXsK6+gp9fB2tppfv7pBL/+eITrl4N8fynAd3ONLBy2EW6UcCve/FKD0YgQvRph9q8ZEncTxG+eJndngrlHOd79oJ03dj9P3wmJrc0Zbq2k+G0lye17KVZWE6xvxFkYNxOwWVF8vnwohu1QYl/HyPw5TfzOBJNrk5zbmmZ+Nc7gSICCpzWkkj7gHf64P8WD+0k21lPcvZPg4aNZMqMN1NfU4vF688EYDHqECuU1Qpc6iSx0cvzTg4iHjeytLuG9cBUTjkLanVVM5noYOeknmw1y4e0OLl4Mce2rY8wMiVjq9iG5ZZxuN3V6HYLXvJ+66lrsJj02UU/lq1Xoag0EJROxJhGnSaTWYGF/jUhNrYjJbEWnM+T/ZasXkf2teH0thGUZs06PMHPURZNPxuGSkSQZj+JBdss0OCUsdgmnJOGWXbjzbZCQJBeyvO2VcLlknB39BAKHGLI2YK+pRdAUl1Gq1VJeVkZpaSmFhYV5irYpKtyZ/5c9Kgpe0LC7uJg9BQUIWq2WUm0ZJS9qKSouQaPR7KBWq1GpVDv6X9So1Ns+DZrHvn8AnmqVpUBU/TEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05d874a5b48f090bc6bb7272b19de8d0/e4706/1440p_Settings_Alarm_Areas.avif 230w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/d1af7/1440p_Settings_Alarm_Areas.avif 460w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/7f308/1440p_Settings_Alarm_Areas.avif 920w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/42d56/1440p_Settings_Alarm_Areas.avif 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/05d874a5b48f090bc6bb7272b19de8d0/a0b58/1440p_Settings_Alarm_Areas.webp 230w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/bc10c/1440p_Settings_Alarm_Areas.webp 460w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/966d8/1440p_Settings_Alarm_Areas.webp 920w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/a8acc/1440p_Settings_Alarm_Areas.webp 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/05d874a5b48f090bc6bb7272b19de8d0/81c8e/1440p_Settings_Alarm_Areas.png 230w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/08a84/1440p_Settings_Alarm_Areas.png 460w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/c0255/1440p_Settings_Alarm_Areas.png 920w", "/en/static/05d874a5b48f090bc6bb7272b19de8d0/610c0/1440p_Settings_Alarm_Areas.png 1295w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/05d874a5b48f090bc6bb7272b19de8d0/c0255/1440p_Settings_Alarm_Areas.png",
              "alt": "Web User Interface - 1440p Series - Alarm Areas",
              "title": "Web User Interface - 1440p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <AreasTable mdxType="AreasTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoCard mdxType="YoutubeVideoCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <InfoBox mdxType="InfoBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "time-controlled-area-activation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#time-controlled-area-activation",
        "aria-label": "time controlled area activation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Time Controlled Area Activation`}</h3>
    <p>{`You want to activate different areas with different sensitivities at certain times of the day? See `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Schedule/"
      }}>{`Alarm/Alarm Schedule`}</a>{` for a description of the time-controlled zone setting.`}</p>
    <h2 {...{
      "id": "alarm-area-adjustments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-area-adjustments",
        "aria-label": "alarm area adjustments permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Area Adjustments`}</h2>
    <h3 {...{
      "id": "sensitivity-size-and-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sensitivity-size-and-position",
        "aria-label": "sensitivity size and position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sensitivity, Size and Position`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <UpdateBox mdxType="UpdateBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`A big area is much more resistant against false alerts. A big area over the whole image will only trigger an alert when a person comes very close to the camera. You can use a slightly higher sensitivity to compensate.`}</p>
    <p>{`To prevent that you might loose actual alarm events, add a smaller area where it is critical - e.g. a window or your front door.`}</p>
    <p>{`The smaller this area is, the higher its sensitivity. To find the optimal setting, keep an eye on your camera´s `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/Log/"
      }}>{`System Log`}</a>{`. If an area causes too many false alerts, make it bigger or decrease it´s sensitivity.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/809150c54e463785793d913607056749/3d405/1440p_Settings_Alarm_Areas_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsTAAALEwEAmpwYAAACU0lEQVQoz33QyU8TUQDH8RekULB2sUvCEirwOnWj01kLKiVUEgOd9970TaeztVVTe9MLMaIGKpHEGE9c0JBwUDyoCJVVCkYTOfpXmRpiDBqTz+V3+F5+QK+t5eY3yZM6qh3DtbpWq9OHG9rsp/z8SXRuQ51dtxa28g9WwP3M+IosLaXklyl5KSUvj6ResLyYjKeXr7L6xYFgH+yNwp6mwe4mFWWtAqUqmVIweDY6djTENVjhkBUOWOEbL76HCRjr4r9L50r9HuD1uvyeFp+nxedt9Xtb/RhhyynlCybJaeDpaPqQ5Tc5cZsTtzjxsyCtxpPxeK/QkGARBsDZyJlwbyDc7QsHO0LBzjBSkFUs6wVTUZRm/IXltzhx51e/L0hvmSQT7xEOpFgZuoFv4kr/+oo8P30hfDoUaA+qRLVtk+aNrFb6X8yUYRvwoUz0R2Pk1fNExBMKuINIUaziTd2wEUL/iuNJ5nyfsJ+CRcYHQtFI1/BQX2KwJ9QZCXZEMCaObWl5XVHzf8WitBpLwFhAOLo0UO7yApfX1e4GbZ2gzX+q3e9yo2zWtEt6wcREBQuj6a8sv8OJe5y4y4kNXng3NCJJVe7xzGV0tz9agUw1xlRhrDoA7wzCiqoatuM0D0MEzF0b20zwHzjxIyeucVI9yb0WxzOZNX54V05vyxO78vXf9uTMNtVumQbN6QaiBWASVFFREWMHHbMQwdjAOQsRq7n+gLGt6WbBKjuGTZQsmKkobx6h204um9NUTSNNlFKinaRSVdGsMp1erJTuLU5OVW5M/gRvj9qBayTOCQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/809150c54e463785793d913607056749/e4706/1440p_Settings_Alarm_Areas_01.avif 230w", "/en/static/809150c54e463785793d913607056749/d1af7/1440p_Settings_Alarm_Areas_01.avif 460w", "/en/static/809150c54e463785793d913607056749/7f308/1440p_Settings_Alarm_Areas_01.avif 920w", "/en/static/809150c54e463785793d913607056749/47db3/1440p_Settings_Alarm_Areas_01.avif 1348w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/809150c54e463785793d913607056749/a0b58/1440p_Settings_Alarm_Areas_01.webp 230w", "/en/static/809150c54e463785793d913607056749/bc10c/1440p_Settings_Alarm_Areas_01.webp 460w", "/en/static/809150c54e463785793d913607056749/966d8/1440p_Settings_Alarm_Areas_01.webp 920w", "/en/static/809150c54e463785793d913607056749/4d989/1440p_Settings_Alarm_Areas_01.webp 1348w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/809150c54e463785793d913607056749/81c8e/1440p_Settings_Alarm_Areas_01.png 230w", "/en/static/809150c54e463785793d913607056749/08a84/1440p_Settings_Alarm_Areas_01.png 460w", "/en/static/809150c54e463785793d913607056749/c0255/1440p_Settings_Alarm_Areas_01.png 920w", "/en/static/809150c54e463785793d913607056749/3d405/1440p_Settings_Alarm_Areas_01.png 1348w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/809150c54e463785793d913607056749/c0255/1440p_Settings_Alarm_Areas_01.png",
              "alt": "Web User Interface - 1440p Series - Alarm Areas",
              "title": "Web User Interface - 1440p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the example (above) all 4 alarm areas are activated - each with a sensitivity of 50%. In the image below you can see a visualization of the camera's motion detection when you move an object through the field of view:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/838a60e77a80311a0d95c7b37429e1ce/1440p_Settings_Alarm_Areas_02.gif",
        "alt": "Web User Interface - 1440p Series - Alarm Areas"
      }}></img></p>
    <p>{`All black pixels are recognized as background and thus ignored. White pixels are pixels in which a change in brightness and/or color has been detected. With the given settings, at least 50% of the pixels in an area must become white before an alarm is triggered. The larger the area, the larger the movement in front of the camera must be to trigger an alarm.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The higher the sensitivity AND the smaller the area of an area, the faster an alarm will be triggered. Thus, in addition to reducing the sensitivity, increasing the areas can also limit false alarms.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/40f614da7be76af60f019099931459a2/91b29/1440p_Settings_Alarm_Areas_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3ElEQVQI12PQ19fX0dHR1tbW0dHRAgNZObnQkOArF89eOHPy0vnTVy+evQJHF85cOHvy/JkTF8+d2rh+DYM2GGhpaWmCgZaWtrS4mLVHRNeut22bn3Rse96142XXjlddO15173rduf1l66Ynndtf9u1937r6ElSztra2JlS3tqy4mJxxgHHTdfOG82aNlyxbLlu1XrFsuWrbcd208aJG6UmD+sv2vfczZp1Bt1lTS1tGXEzFItim87ZN62W79mv2nTccum46dN107rlt137VqvG8fedN/xlPypdcBAAUr22GPyRCdgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40f614da7be76af60f019099931459a2/e4706/1440p_Settings_Alarm_Areas_03.avif 230w", "/en/static/40f614da7be76af60f019099931459a2/d1af7/1440p_Settings_Alarm_Areas_03.avif 460w", "/en/static/40f614da7be76af60f019099931459a2/7f308/1440p_Settings_Alarm_Areas_03.avif 920w", "/en/static/40f614da7be76af60f019099931459a2/01a13/1440p_Settings_Alarm_Areas_03.avif 983w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/40f614da7be76af60f019099931459a2/a0b58/1440p_Settings_Alarm_Areas_03.webp 230w", "/en/static/40f614da7be76af60f019099931459a2/bc10c/1440p_Settings_Alarm_Areas_03.webp 460w", "/en/static/40f614da7be76af60f019099931459a2/966d8/1440p_Settings_Alarm_Areas_03.webp 920w", "/en/static/40f614da7be76af60f019099931459a2/b4d37/1440p_Settings_Alarm_Areas_03.webp 983w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/40f614da7be76af60f019099931459a2/81c8e/1440p_Settings_Alarm_Areas_03.png 230w", "/en/static/40f614da7be76af60f019099931459a2/08a84/1440p_Settings_Alarm_Areas_03.png 460w", "/en/static/40f614da7be76af60f019099931459a2/c0255/1440p_Settings_Alarm_Areas_03.png 920w", "/en/static/40f614da7be76af60f019099931459a2/91b29/1440p_Settings_Alarm_Areas_03.png 983w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/40f614da7be76af60f019099931459a2/c0255/1440p_Settings_Alarm_Areas_03.png",
              "alt": "Web User Interface - 1440p Series - Alarm Areas",
              "title": "Web User Interface - 1440p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`As expected, the condition - min. 50% of the pixels within an area must change - is only fulfilled for areas 3 and 4. Area 1 and 2 therefore do not trigger an alarm.`}</p>
    <p>{`In a second test run, the sensitivity of all areas is increased to 75%. The position and size are retained. Due to the higher sensitivity, it is now already sufficient if only a quarter of an area sees a movement:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/9d453258bc5bee37d8d8a4f79713460c/1440p_Settings_Alarm_Areas_04.gif",
        "alt": "Web User Interface - 1440p Series - Alarm Areas"
      }}></img></p>
    <p>{`With this setting, all areas now trigger:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/037746ebd20a99c0d2309ad33755d7ba/58bb7/1440p_Settings_Alarm_Areas_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVQI12PQ0dHR0tLS1tbW0tLS1NTU0NCQlZXLzc5Ys2LJskXzVi5dsGrZwpVLEWj5kvnLFs1bsmDOqmWLGHR0dLTBAKZZU1xEpL53ztnn/4/e/X7i4e/TT/6dfvLvDAQ9/X/8wa/j93+eePDz3PP/DBBrNcFAS0tLTU1DUlBALajJvO2WRf1Zi6YLVs2XrFsuW7Vctmq9Ytt2xaDqtGHVGbOWa84THzIgW6ulpamqpiEpJKgT1ubQc9+h/bJj53XnnpsuPTede246995y67tl3XzRqumSbfftwFnPAbMRbzdEY3/kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/037746ebd20a99c0d2309ad33755d7ba/e4706/1440p_Settings_Alarm_Areas_05.avif 230w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/d1af7/1440p_Settings_Alarm_Areas_05.avif 460w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/7f308/1440p_Settings_Alarm_Areas_05.avif 920w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/01068/1440p_Settings_Alarm_Areas_05.avif 985w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/037746ebd20a99c0d2309ad33755d7ba/a0b58/1440p_Settings_Alarm_Areas_05.webp 230w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/bc10c/1440p_Settings_Alarm_Areas_05.webp 460w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/966d8/1440p_Settings_Alarm_Areas_05.webp 920w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/8a0f1/1440p_Settings_Alarm_Areas_05.webp 985w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/037746ebd20a99c0d2309ad33755d7ba/81c8e/1440p_Settings_Alarm_Areas_05.png 230w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/08a84/1440p_Settings_Alarm_Areas_05.png 460w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/c0255/1440p_Settings_Alarm_Areas_05.png 920w", "/en/static/037746ebd20a99c0d2309ad33755d7ba/58bb7/1440p_Settings_Alarm_Areas_05.png 985w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/037746ebd20a99c0d2309ad33755d7ba/c0255/1440p_Settings_Alarm_Areas_05.png",
              "alt": "Web User Interface - 1440p Series - Alarm Areas",
              "title": "Web User Interface - 1440p Series - Alarm Areas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "background-compensation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#background-compensation",
        "aria-label": "background compensation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Background Compensation`}</h3>
    <p>{`Movements in the background - e.g. trees with leaves moving in the wind - are a common reason for false alarms. The WQHD cameras have an algorithm that detects such continuous movements and helps the camera to filter them out. In the following example, we have pointed our camera at a fan.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/d0d28d15afce8daeaf10b1825a5b72fa/1440p_Settings_Alarm_Areas_06.gif",
        "alt": "Web User Interface - 1440p Series - Alarm Areas"
      }}></img></p>
    <p>{`When starting the fan, the movement is detected and would trigger an alarm. After a short time, however, the background detection jumps in and suppresses the signal - the fan thus falls into the background and the image turns black. When the fan is switched off, the frequency with which the rotor moves changes and the fan "falls" out of the background again.`}</p>
    <p>{`This function suppresses false alarms. But it can also suppress "real" alarms. For example, if a person stays in front of the camera for a long time - in this case you often get an alarm only when entering and leaving.`}</p>
    <p><img parentName="p" {...{
        "src": "/en/5b536e63313648593c6b5c0ced920522/1440p_Settings_Alarm_Areas_07.gif",
        "alt": "Web User Interface - 1440p Series - Alarm Areas"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      